import React from 'react';

// Path to the logo file on your project
import BoiseLogo from '../assets/boise-logo.svg';

const Logo = () => (
  <img src={BoiseLogo} alt="Broadway in Boise logo" style={{ width: 180 }} />
);

export default Logo;
